import { useRef } from 'react';
import BottomSheet from '../../../features/visual/BottomSheet/BottomSheet';
import BulksPlan from '../../../features/visual/BulksPlan/BulksPlan';
import ListLink from '../../../features/visual/VisualBottomLinks/ListLink/ListLink';
import NextLink from '../../../features/visual/VisualBottomLinks/NextLink/NextLink';
import VisualFlatsInfo from '../../../features/visual/VisualFlatsInfo/VisualFlatsInfo';
import VisualInfo from '../../../features/visual/VisualInfo/VisualInfo';
import VisualNav from '../../../shared/visual-nav/VisualNav';
import wordEndings from '../../../tools/_word-endings';
import ScrollHelperBlackVisual from '../../../tools/helpers/ScrollHelperBlackVisual';
import style from './VisualBulkMobile.module.scss';

const VisualBulkMobile = ({
  data,
  firstBulk,
  switchTitle,
  getAvailableFlats,
  getAvailableFlatsLength,
  activeBulk,
  secondBulkId,
  activeBulkId,
  bulkLock,
}) => {
  const sheetRef = useRef(null);

  return (
    <>
      <div className={style.wrapper}>
        <div className={style.rightBlock__tabs}>
          <VisualNav
            mobTabStyle={style.mobTabStyle}
            mobTabActiveStyle={style.mobTabActiveStyle}
            mobTabBehindStyle={style.mobTabBehindStyle}
            page={2}
          />
        </div>

        <BulksPlan
          sheetRef={sheetRef}
          someStyles={style.leftBlock}
          switchTitle={switchTitle}
          activeBulkId={activeBulkId}
          data={data}
        />

        <BottomSheet>
          <div className={style.rightBlock} ref={sheetRef}>
            <div className={style.info}>
              <div className={style.listLink}>
                <ListLink />
              </div>
              <h1 className={style.title}>{switchTitle(activeBulk)}</h1>
              {!bulkLock && (
                <p className={style.description}>{`В продаже ${getAvailableFlatsLength()} ${wordEndings(
                  firstBulk.length,
                )}`}</p>
              )}
              {bulkLock && (
                <p className={style.description + ' ' + style.description__lock}>
                  К&nbsp;сожалению, пока нет доступных квартир, но&nbsp;можно оставить заявку
                </p>
              )}
              {!bulkLock && activeBulk !== null && <VisualInfo data={data} activeBulk={activeBulkId} />}
              {(activeBulk !== secondBulkId || activeBulk === null) && (
                <VisualFlatsInfo flats={getAvailableFlats(activeBulk)} />
              )}
              {activeBulk !== null && (
                <NextLink bulk={activeBulkId ? Number(activeBulkId) : ''} activeBulk={activeBulk} />
              )}
            </div>
          </div>
        </BottomSheet>
      </div>
      <ScrollHelperBlackVisual />
    </>
  );
};

export default VisualBulkMobile;
