import { useRef } from 'react';
import { isDesktop } from 'react-device-detect';
import BulksPlan from '../../../features/visual/BulksPlan/BulksPlan';
import VisualBottomLinks from '../../../features/visual/VisualBottomLinks/VisualBottomLinks';
import VisualFlatsInfo from '../../../features/visual/VisualFlatsInfo/VisualFlatsInfo';
import VisualInfo from '../../../features/visual/VisualInfo/VisualInfo';
import VisualNav from '../../../shared/visual-nav/VisualNav';
import wordEndings from '../../../tools/_word-endings';
import ScrollHelperBlackVisual from '../../../tools/helpers/ScrollHelperBlackVisual';
import { useResponsiveStyles } from '../../../tools/hooks/useResponsiveStyles';
import style from './VisualBulk.module.scss';

const VisualBulk = ({
  data,
  firstBulk,
  switchTitle,
  getAvailableFlats,
  getAvailableFlatsLength,
  activeBulk,
  secondBulkId,
  activeBulkId,
  bulkLock,
}) => {
  const bottomLinksRef = useRef(null);
  const infoRef = useRef(null);

  useResponsiveStyles(bottomLinksRef, infoRef, style.infoWideScreen, style.bottomLinksWideScreen);

  return (
    <>
      <div className={style.wrapper}>
        <BulksPlan data={data} activeBulkId={activeBulkId} switchTitle={switchTitle} />
        <div className={style.rightBlock} ref={infoRef}>
          {isDesktop && <div className={style.gradient} />}
          <div className={style.rightBlock__tabs}>
            <VisualNav page={2} />
          </div>

          <div className={style.info}>
            <h1 className={style.title}>{switchTitle(activeBulk)}</h1>
            {!bulkLock && (
              <p className={style.description}>{`В продаже ${getAvailableFlatsLength()} ${wordEndings(
                firstBulk.length,
              )}`}</p>
            )}
            {bulkLock && (
              <p className={style.description + ' ' + style.description__lock}>
                К&nbsp;сожалению, пока нет доступных квартир, но&nbsp;можно оставить заявку
              </p>
            )}
            {!bulkLock && activeBulk !== null && <VisualInfo data={data} activeBulk={activeBulkId} />}
            {(activeBulk !== secondBulkId || activeBulk === null) && (
              <VisualFlatsInfo flats={getAvailableFlats(activeBulk)} />
            )}
            <VisualBottomLinks
              visual={data}
              bulk={activeBulkId ? Number(activeBulkId) : null}
              activeBulk={activeBulk}
              bottomLinksRef={bottomLinksRef}
            />
          </div>
        </div>
      </div>
      <ScrollHelperBlackVisual />
    </>
  );
};

export default VisualBulk;
