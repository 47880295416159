import { useEffect } from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { modelFormSelector } from '../../../entities/ComponentsSlice';
import {
  activeBulkSelector,
  bulkLockSelecetor,
  dataSelector,
  fetchErrorSelector,
  fetchVisualBulk,
  setActiveBulk,
  setBulkLock,
} from '../../../entities/VisualBulkSlice';
import { setBottomSheetIsOpen } from '../../../entities/VisualFloorSlice';
import HelmetComponent from '../../../shared/helmet-component/HelmetComponent';
import LoaderCircle from '../../../shared/loader-circle/LoaderCircle';
import VisualBulk from '../../../widgets/visual/VisualBulk/VisualBulk';
import VisualBulkMobile from '../../../widgets/visual/VisualBulk/VisualBulkMobile';
import style from './VisualBulkPage.module.scss';

const VisualBulkPage = () => {
  const { pathname } = useLocation();
  const { houseId } = useParams();
  const dispatch = useDispatch();
  const isModalFormOpen = useSelector(modelFormSelector);
  const data = useSelector(dataSelector);
  const thirdBulk = data?.flats?.filter(item => item.bulk.id === 10099);
  const firstBulk = data?.flats?.filter(item => item.bulk.id === 9758);
  const availableFlats = data?.flats?.filter(item => item.bulk.id === 10099 || item.bulk.id === 9758);
  const fetchError = useSelector(fetchErrorSelector);
  const firstBulkId = 'k2_1_9758';
  const secondBulkId = 'k2_2';
  const thirdBulkId = 'k2_3_10099';
  const activeBulk = useSelector(activeBulkSelector);
  const activeBulkId = Number(activeBulk?.split('_').slice(2)?.join('_'));
  const bulkLock = useSelector(bulkLockSelecetor);

  const switchTitle = bulkId => {
    if (bulkId === firstBulkId) return 'Корпус 2.1';
    if (bulkId === secondBulkId) return 'Корпус 2.2';
    if (bulkId === thirdBulkId) return 'Корпус 2.3';
    return 'Выберите корпус';
  };

  const getAvailableFlatsLength = bulkId => {
    if (bulkId === firstBulkId) return firstBulk.length;
    if (bulkId === secondBulkId) return null;
    if (bulkId === thirdBulkId) return thirdBulk.length;
    return availableFlats.length;
  };

  const getAvailableFlats = bulkId => {
    if (bulkId === firstBulkId) return firstBulk;
    if (bulkId === thirdBulkId) return thirdBulk;
    return availableFlats;
  };

  useEffect(() => {
    if (activeBulk === null) dispatch(setBulkLock(false));
  }, [activeBulk]);

  useEffect(() => {
    if (!data) {
      dispatch(fetchVisualBulk());
    }
    document.body.style.overflow = 'hidden';

    if (!isModalFormOpen && isMobile) {
      setTimeout(() => {
        document.body.style.overflow = 'hidden';
      }, 300);
    }

    return () => {
      document.body.style.overflow = 'auto';
      dispatch(setActiveBulk(null));
      if (isMobile) return dispatch(setBottomSheetIsOpen(false));
    };
  }, [dispatch, data, isModalFormOpen]);

  if (fetchError) return <div className={style.error}>Не удалось загрузить данные</div>;
  if (!data) {
    return <LoaderCircle />;
  }

  return (
    <>
      <HelmetComponent
        page={pathname?.slice(1)}
        title={`Очередь ${houseId} | ЖК Moments`}
        description={`Очередь ${houseId}. Квартиры рядом с парком «Покровское-Стрешнево» в ЖК бизнес-класса Moments от FORMA. Гармоничная архитектура. 15 минут до центра. 5 минут пешком до МЦК.`}
      />
      <h1 style={{ display: 'none' }}>Очередь</h1>
      {(!isMobileOnly && (
        <VisualBulk
          data={data}
          firstBulk={firstBulk}
          switchTitle={switchTitle}
          getAvailableFlatsLength={() => getAvailableFlatsLength(activeBulk)}
          getAvailableFlats={() => getAvailableFlats(activeBulk)}
          activeBulk={activeBulk}
          secondBulkId={secondBulkId}
          activeBulkId={activeBulkId}
          bulkLock={bulkLock}
        />
      )) ||
        (isMobileOnly && (
          <VisualBulkMobile
            data={data}
            firstBulk={firstBulk}
            switchTitle={switchTitle}
            getAvailableFlatsLength={() => getAvailableFlatsLength(activeBulk)}
            getAvailableFlats={() => getAvailableFlats(activeBulk)}
            activeBulk={activeBulk}
            secondBulkId={secondBulkId}
            activeBulkId={activeBulkId}
            bulkLock={bulkLock}
          />
        ))}
    </>
  );
};

export default VisualBulkPage;
