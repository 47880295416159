import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { activeQueueSelector } from '../../../entities/VisualSlice';
import wordEndings from '../../../tools/_word-endings';
import style from './VisualFlatsInfo.module.scss';

function VisualFlatsInfo({ flats }) {
  const activeQueue = useSelector(activeQueueSelector);
  const firstBulk = flats.filter(flat => flat.bulk.id === 9335);
  const secondBulk = flats.filter(flat => flat.bulk.id === 10099 || flat.bulk.id === 9758);

  const getFilteredFlats = () => {
    if (activeQueue === null) return flats;
    if (activeQueue === 1) return firstBulk;
    return secondBulk;
  };

  const memoizedInfo = useMemo(() => {
    return getFilteredFlats().reduce((acc, flat) => {
      const { rooms, price } = flat;
      const priceNum = Math.round(Number(price.replace(/\s+/g, '')) / 1000000);
      acc[rooms] = acc[rooms] || { count: 0, minPrice: Number.MAX_VALUE };
      acc[rooms].count++;
      if (priceNum < acc[rooms].minPrice) {
        acc[rooms].minPrice = priceNum;
      }
      return acc;
    }, {});
  }, [getFilteredFlats]);

  const memoizedInfoArray = useMemo(() => {
    return Object.keys(memoizedInfo)
      .map(rooms => ({
        rooms,
        count: memoizedInfo[rooms].count,
        minPrice: memoizedInfo[rooms].minPrice,
      }))
      .sort((a, b) => {
        if (a.rooms === 'studio') {
          return -1;
        }
        if (b.rooms === 'studio') {
          return 1;
        }
        return 0;
      });
  }, [memoizedInfo]);

  return (
    <>
      <div className={style.visualFlatsInfo}>
        {memoizedInfoArray.map(item => (
          <div key={item.rooms} style={{ display: item.count > 0 ? 'block' : 'none' }}>
            <div className={style.visualFlatsInfo__rooms}>
              <div className={style.visualFlatsInfo__rooms__item}>
                {(() => {
                  switch (item.rooms) {
                    case 'studio':
                      return 'Студия';
                    case '1':
                      return item.rooms + ' ' + 'спальня';
                    case '5':
                      return item.rooms + ' ' + 'спален';
                    default:
                      return item.rooms + ' ' + 'спальни';
                  }
                })()}
              </div>
              <div className={style.visualFlatsInfo__rooms__item}>
                {item.count} {wordEndings(item.count)}
              </div>
              <div className={style.visualFlatsInfo__rooms__item}>От {item.minPrice} млн. ₽</div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default VisualFlatsInfo;
